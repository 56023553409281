import { render, staticRenderFns } from "./TableItem.vue?vue&type=template&id=4d4a7093&scoped=true"
import script from "./TableItem.vue?vue&type=script&lang=js"
export * from "./TableItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_amhcwhcsvqr2myze6kddhh7yxm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d4a7093",
  null
  
)

export default component.exports